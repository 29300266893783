import React from 'react';
import '../App.scss';
import pascalelaffon from '../assets/logos/pascalelaffon-titre.png';
import bg from '../assets/photos/bg-intro2.jpg';

const Intro = () => {

  
  return(
    <div className='section' id="intro">
        {/*pour le referencement*/}
        <h1 style={{display:'none'}}>Pascale Laffon, micropracteur à Teyran</h1>

        <div className='grid2' id='grid2-justify' style={{marginTop:'10rem'}}>

          <div className='grid2-item' id='grid2-item-justify' >
            <img id='titre-logo' src={pascalelaffon} alt="Pascale Laffon écrit à l'encre de Chine." />
            <h1 style={{color:'black', marginTop:'-50px'}}>micropracteur</h1>
            <p id='intro-p'> optimisez votre santé, réharmonisez votre corps & améliorez votre qualité de vie par une pratique de soins complémentaires innovante</p>
          </div>
       
        <div className='grid2-item-right' id='bg-intro-justify'>
          <img id='bg-intro' src={bg} alt="Les mains de Pascale Laffon touche le visage d'une jeune femme dans le cadre d'une séance de micropraxie. Tons roses-orangés doux. Image avec un léger grain." />
        </div>

        </div>
    </div>
   )
  }
export default Intro;

