import React, { useState } from 'react';
import a1 from '../assets/icons/icons-carousel1.png';
import a2 from '../assets/icons/icons-carousel2.png';
 function Carousel({diplome}) {

const [currentIndex, setCurrentIndex] = useState(0);

  // Navigation du carousel
  //// Utilise l'index pour avancer/reculer
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 === diplome.length ? 0 : prevIndex + 1
    );
  };
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? diplome.length - 1 : prevIndex - 1
    );
  };
  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };


return (
  <>
  <div className='flex-col' style={{flexDirection:'row'}}>
      <div className='flex-row-items' style={{marginTop:0}}>
            
          <div style={{flexDirection:'column'}} id='menu'>

            <div className='menu-item'>
              <div onClick={handlePrevious} >
                <img src={a2} style={{width:'2rem'}}/>
              </div>
              <p id='date'>{diplome[currentIndex].année}</p>
              <div onClick={handleNext}>
                <img src={a1} style={{width:'2rem'}}/>
              </div>
            </div>

            <p id='date-diplome'>{diplome[currentIndex].diplome}</p>

            <p>{diplome[currentIndex].ville}</p>

          </div>
          </div>
          

          <div className="indicator">
            {diplome.map((_, index) => (
              <div
                key={index}
                onClick={() => handleDotClick(index)}
              ></div>
            ))}
          </div>
          
    </div>
    </>
 )
}

export default Carousel