import React from 'react';
import '../App.scss';
// import { gsap } from "https://cdn.skypack.dev/gsap@3.11.4";
// import ScrollTrigger from "gsap/ScrollTrigger";
 

function Citation() {
 
  return (
    <div className='section'>
      <div className='main'>
        <h2 id='citation'>INVINCIBLE EST LA DOUCEUR</h2>
        <p className='typewriter'>MARC-AURÈLE</p>
      </div>
    </div>
    
  )
}

export default Citation

