import React from 'react';
import '../App.scss';
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import pin from '../assets/icons/pin.png';

const Map = () => {

  const markers = [
    {
      geocode : [43.68370194356211,3.9287150468383674],
      popUp : 'Mon cabinet'
    }
  ]
  const customIcon = new Icon ({
    iconUrl: pin,
    iconSize: [38,38],
  })
  const redirectToGoogleMaps = () => {
    const address = '43.68370194356211,3.9287150468383674';
    const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(address)}`;
    window.open(googleMapsUrl, '_blank'); // Ouvre dans une nouvelle fenêtre
  }


  return (

    <div className='mapbox'>
      <MapContainer center={[43.68370194356211,3.9287150468383674]} zoom={25} >
  
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />


        {markers.map(marker =>(
          <button onClick={redirectToGoogleMaps}>
            <Marker position={marker.geocode} icon={customIcon} eventHandlers={{
              click: () => redirectToGoogleMaps('43.68370194356211,3.9287150468383674')
            }}></Marker>
          </button>
        ))
        }

      </MapContainer>
    </div>

  );
}

export default Map


