import React, { useState, useEffect } from 'react';
import '../App.scss';
import image1 from '../assets/photos/carousel-img1.jpg';
import image2 from '../assets/photos/carousel-img2.jpg';
import image3 from '../assets/photos/eau.jpg';
import lotus from '../assets/illustration/lotus-header.png';

function Fonctionnement() {
  const carouselArr = [
    {
      index: 0,
      image: image1,
      alt:"Jeune fille de dos assise et portant un pull rose",
      title: 'Avant la séance',
      menu:'Avant',
      description: 'Nous prenons le temps de préciser ensemble vos motifs de consultation, votre état général, vos antécédents médicaux ainsi que vos éventuels traitements en cours. Aucune tenue particulière n\'est exigée. Vous restez habillé durant la séance. '
    },
    {
      index: 1,
      image: image2,
      alt:"Pascale Laffon posant ses mains sur le dos d'une patiente lors d'une séance de micropraxie.",
      title: 'Déroulé de la séance',
      menu:'Déroulé',
      description: 'Les micro-manipulations douces que j\'effectue sur votre corps me permettent de trouver ses mémoires traumatiques et de les évacuer. Par mes mains, je viens apporter à votre corps les informations dont il a besoin pour s’auto-réparer.',
    },
    {
      index: 2,
      image: image3,
      alt:"Des mains tenant une bouteille d'eau en verre. Fond de couleur marron doux.",
      title: 'Après la séance',
      menu:'Après',
      description: 'Les processus d’auto-réparations vont agir pendant 3 à 4 semaines. Durant les trois premiers jours, il est conseillé de s’hydrater régulièrement, de ne pas pratiquer d’activités physiques intenses. Il est possible que vous ressentiez de la fatigue.',
    },
  ];
   //L'index de l'image active (div .carouselImage)  
   const [activeIndex, setActiveIndex] = useState(0);
   //L'image sélectionnée
   const [activeImage, setActiveImage] = useState(carouselArr[0].image); // par défaut première catégorie (img)
   //Le titre à afficher
   const [itemTitre, setItemTitre]=useState(carouselArr[0].title);
   //Le texte à afficher
   const [itemText, setItemText]=useState(carouselArr[0].description);
    //Alt
    const [itemAlt, setItemAlt]=useState(carouselArr[0].alt);
 
   // Change l'image selon catégories cliquées (élément actif)
     const changeActiveCatégorie = (index) => {
      setActiveIndex(index);
      //  const selectedImage = carouselArr.find((item) => item.index === index); // si index recu = index objet
        setItemTitre(carouselArr[index].title); // changer titre en fonction
        setItemText(carouselArr[index].description); // changer titre en fonction
        setActiveImage(carouselArr[index].image); // changer image en fonction
        setItemAlt(carouselArr[index].alt); // changer alt en fonction
        updateMenuClasses(index);
      };
      const changeItem = (activeIndex) => {
    };

    // CHANGER COULEUR MENU SELECTIONNÉ
      const updateMenuClasses = (activeIndex) => {
      const menuItems = document.querySelectorAll('#menu-carousel p');
      menuItems.forEach((menuItem, index) => {
        if (index === activeIndex) {
            menuItem.style.color = '#522D24';
        } else {
          menuItem.style.color = '#9c7a72';
        }
      });
    };
   
    // Effet secondaire pour mettre à jour les classes du menu lors du changement d'index
     useEffect(() => {
         updateMenuClasses(activeIndex);
       }, [activeIndex]);
 
 
return (
        <>
          <div className='carouselContainer'>

            <div className='carouselText'>
                  {/* Contenu du texte du carousel*/}
                  <h2 id='carouselText-p'>{itemTitre}</h2>
                  <div id='separator'>
                    <div id='tiret'></div>
                    <img src={lotus} alt="illustration minimaliste au trait d'une fleur de lotus" />
                    <div id='tiret'></div>
                  </div>
                  <p>{itemText}</p>
        
                  {/* Menu du carousel */}
                    <div id='menu-carousel'>
                      {carouselArr.map((item, index) => (
                        <p key={index} onClick={() => changeActiveCatégorie(index)}>{item.menu}</p>
                      ))}
                    </div>
            </div>
    

          {/* Image du carousel */}
            <div className='carouselImg'>
              <img src={activeImage} alt={itemAlt} />
              {/* <Prev /> */}
            </div>



          </div>

        </>
      );
}
export default Fonctionnement