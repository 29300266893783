// icon:phone | Feathericons https://feathericons.com/ | Cole Bemis
import * as React from "react";

function Arrow(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19" {...props}></polyline></svg>
  );
}

export default Arrow;
