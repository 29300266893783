// App.js
import React, { useEffect, useState } from 'react';
import './App.scss';
import Header from './components/Header.js';
import Intro from './views/Intro.js';
import Citation from './views/Citation.js';
import Discipline from './views/Discipline.js';
import Video from './views/Video.js';
import Fonctionnement from './views/Fonctionnement.js';
import Bio from './views/Bio.js';
import Contact from './views/Contact.js';
import Bouton from './components/Bouton.js';
import Footer from './components/Footer.js';


const App = () => {

  //Invisibiliser roue à la fin du document
  const [isPageScrolled, setIsPageScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
      //Vérifier si la page est entièrement défilée
      const scrollThreshold = document.body.offsetHeight - window.innerHeight - 900; // Supprimer footer à partir de la section 'immersion au coeur de la nature
      const isScrolledToBottom = window.scrollY >= scrollThreshold;
      setIsPageScrolled(isScrolledToBottom);
    };
      //Ajouter un écouteur d'événement pour le défilement de la fenêtre
      window.addEventListener('scroll', handleScroll);
     //Nettoyer l'écouteur d'événement lors du démontage du composant
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  return (
    <div className="scrollable-container">
      <Header />
      <Intro />
      <Citation />
      <Discipline />
      <Video />
      <Fonctionnement />
      <Bio />
      <Contact />
      <Footer />
      {!isPageScrolled && <Bouton />}
  </div>
        
  );
};

export default App;