import '../App.scss'
import React from 'react';
import cabinet from '../assets/illustration/cabinet.png'


function Footer() {
  const footer=[
    {
      adresse:'3, AVENUE DE CASTRIES - 34820 Teyran',
      numéro:'07 63 99 88 76',
      // mail:'pascale.laffon@gmail.com',
      copyright:'© réalisation : maria lou diaz',
    }
  ]
  return (
    <>
        {/*LE FOOTER*/}
          <div className='footer'>
            <div className='grid2'>
              <div className='grid2-item' id='contact'>
                {footer.map(item => (
                <div key={item.id}>
                  <p>{item.adresse}</p>
                  <p>{item.numéro}</p>
                  <p>{item.mail}</p>
                  <p id='cp'>{item.copyright}</p>
                </div>
                ))}
              </div>
              <div className='grid2-item-right'>
                <img src={cabinet} alt="Illustration à l'encre de chine du cabinet"/>
              </div>
            </div>
          </div>
    </>
  )
}
export default Footer