import '../App.scss';
import Logo from '../assets/logos/logo-pascalelaffon-header.png'
import Lotus from '../assets/illustration/lotus-header.png'


const Header = () => (
  <div className='header-container'>
      
      <a href="#intro">
        <img className='logo' src={Logo} alt="logo de Pascale Laffon"/>
        <img className='logo' src={Lotus} alt="dessin d'un lotus"/>
      </a>
      
 </div>
)

export default Header