import React from 'react';
import '../App.scss';
import pascale from '../assets/illustration/pascale-laffon-portrait.png';
import Arrow from '../assets/pictogramme/Arrow';
import Carousel from "../components/Carousel";

function Bio() {

  const diplome = [
    {
      année:'1985',
      diplome:'Diplôme masseur-kinésithérapeute',
      ville:'Montpellier',
    },
    {
      année:'2006',
      diplome:'Praticien en shiatsu (Yoseido Shiatsu)',
      ville:'Bruxelles',
    },
    {
      année:'2010',
      diplome:'Formation aux techniques hypotensives',
      ville:'Sphère thoraco-abdomino-pelvienne',
    },
    {
      année:'2011',
      diplome:'Formation à la rééducation ',
      ville:'Périnéo-sphinctèrienne',
    },
    {
      année:'2019',
      diplome:'Validation 1er degré',
      ville:'Micro-kiné',
    },
    {
      année:'2020',
      diplome:'validation second degré',
      ville:'Micro-kiné',
    },
    {
      année:'2021',
      diplome:'Diplôme de micropraxie',
      ville:'',
    },
  ];

  return (
    <div className='section' >
     
      <div className='grid2'>

        <div className='grid1-row2'>
          <div className='grid1-row2-item1' id='bio'>
            <h2> Pascale Laffon </h2>
            <h3> Traiter à la fois le corps et l'esprit </h3>
            <p id='textBio'> J’exerce en tant que micropracteur depuis 4 ans. J'ai toujours été attirée par le soin et l'humain. J'ai tout de suite été frappée par les résultats que pouvait apporter cette pratique, là où d’autres montraient leurs limites. Émotionnel, mental et physique sont intimement liés. La micropraxie est une méthode qui permet de traiter la personne dans son entièreté </p>
          </div>

          <div className='grid1-row2-item2'>            
            <Carousel diplome={diplome}/>
          </div>

        </div>

        <div className='portrait'>
          <img src={pascale} alt="Portrait dessiné à l'encre de chine de Pascale Laffon"/>
        </div>

      </div>
     

     
   

        
    </div>
  )
}

export default Bio