import React from 'react';
import '../App.scss';
import TextTransition, { presets } from 'react-text-transition';

import image1 from '../assets/illustration/icons-mains.png';
import image2 from '../assets/illustration/icons_visage.png';
import image3 from '../assets/illustration/icons-cabinet.png';



const Discipline = () => {

  const data = [
    {
      id: 1,
      image: image1,
      alt: "Deux mains s'élevant vers le ciel dans uns style minimaliste à lencre de chine.Pour illustrer la catégorie la micropraxie",
      title: 'La micropraxie',
      description: 'Je travaille sur la base de mini-manipulations douces qui m’aident à trouver les mémoires traumatiques de votre corps.'
    },
    {
      id: 2,
      image: image2,
      alt: "Un visage et une fleur de lotus dans un style minimaliste à l'encre de chine. Pour illustrer la catégorie pour qui?",
      title: 'Pour qui ?',
      description: 'Pour tous : adultes, enfants, personnes âgées, personne à mobilité réduite',
    },
    {
      id: 3,
      image: image3,
      alt: "une maison dessinée en un seul trait, style minimaliste. Pour illustrer la catégorie le cabinet",
      title: 'Le cabinet',
      description: 'Vous me retrouverez au 3 bis Avenue de Castries, 34820 Teyran',
    },
  ];

  const h3 = ['détecter les traumatismes non-évacués','réinformer le corps et le traiter dans sa globalité','restaurer la vitalité de l’organisme']; 
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
    () => setIndex((index) => index + 1),
    3000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return(
    <>
    <div className='section'>

        <div className='.grid1-row2'>

          <div className='grid1-row2-item1'>

            <h2>La micropraxie</h2>
            <h3 id='text-transition'><TextTransition>{h3[index % h3.length]}</TextTransition></h3>
            <div className='paragraphe'>
              <p>Je travaille sur la capacité d'auto-réparation du corps que nous possédons tous.</p>
              <p>La micropraxie est une méthode qui travaille sur le corps dans sa globalité.</p>
            </div>

          </div>

          <div className='grid1-row2-item2' id='grid3'>
            {data.map((el, index) => (

              <div key={index} className='grid3-item'>
                  <img src={data[index].image} alt={data[index].alt} />
                  <p id='titreMenu'>{data[index].title}</p>
                  <p>{data[index].description}</p>
              </div>

            ))}
          </div>


        </div>

    </div>
    </>
    
   )
  }
export default Discipline;

