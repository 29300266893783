import '../App.scss'
import React from 'react';
import lotus from '../assets/illustration/fleurlotus.png';
import Map from '../components/Map'


function Contact() {

  const footer=[
    {
      adresse:'3 BIS, AVENUE DE CASTRIES - 34820 Teyran',
      numéro:'07 63 99 88 76',
      // mail:'pascale.laffon@gmail.com',
      copyright:'© réalisation : maria lou diaz',
    }
  ]
  return (
    <div className='section'>
        <div id='map'>
          <div id='map-lotus'>
            <img src={lotus} style={{width:'15em', zIndex:'-1'}} alt="Une fleur de lotus à l'encre de chine, placée derrière la carte."/>
          </div>
          <Map />
        </div>
    </div>
  )
}

export default Contact