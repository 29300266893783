
import React from 'react'
import '../App.scss';
import videoDesktop from '../assets/video/MVI_0460.mp4';


const Video = () => {
  
return (
<>
    <div className='film'>
      <div className='background-video'>
        <video className='desktop' autoPlay loop muted>
          <source src={videoDesktop} type="video/mp4" />
        </video>
        {/* <video className='mobile' autoPlay loop muted>
          <source src={videoMobile} type="video/mp4" />
        </video> */}
      </div>
    </div> 
</>
    
  );
};

export default Video;